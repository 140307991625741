app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('th', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',

      'form.action.delete': 'ลบ',
      'recaptcha.language': 'th',
      'paypal.language': 'th_TH',
      close: 'ปิด',

      'form.validation.required': 'กรอก {{field_name}} ให้ครบถ้วน',
      'form.validation.maxlength': '{{field_name}} ยาวเกินไป',
      'form.validation.maxlength.with.number':
        '{{field_name}} is up to {{max_length}} characters',
      'form.validation.minlength': '{{field_name}} สั้นเกินไป',
      'form.validation.invalid': '{{field_name}} ไม่ถูกต้อง',
      'form.validation.pattern': '{{field_name}} ไม่ถูกต้อง',
      'form.validation.pattern.recipient-name':
        'ไม่อนุญาตให้ใช้ช่องว่าง หรือมากกว่า 5 ตัวอักษร',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} ต้องใส่เลข 10 หลัก',
      'form.validation.pattern.711_cross_border.delivery_address':
        'โปรดใส่ข้อมูลเป็นภาษาอังกฤษสำหรับการจัดส่งแบบ cross-border',
      'form.validation.email': '{{field_name}} ไม่ถูกต้อง',
      'form.validation.id_no':
        'กรุณากรอกหมายเลขประจำตัวประชาชนของผู้รับให้ถูกต้องเพื่อความสะดวกในการนำเข้าสินค้า',
      'form.validation.passport':
        'กรุณากรอกหมายเลขหนังสือเดินทางของผู้รับให้ถูกต้องเพื่อความสะดวกในการนำเข้าสินค้า',
      'form.validation.unique': '{{field_name}} ถูกใช้ไปแล้ว โปรดเลือกอีกครั้ง',
      'form.validation.confirmation': 'รหัสผ่านไม่ตรงกัน',
      'form.validation.policy':
        'คุณต้องยอมรับต่อข้อกำหนดและเงื่อนไขเพื่อดำเนินการต่อ',
      'form.validation.delivery_option.outlying':
        'ประเภทการจัดส่งที่คุณเลือกไม่สามารถใช้ได้กับเกาะเล็กรอบนอกในไต้หวัน โปรดเปลี่ยนที่อยู่จัดส่งหรือเลือกวิธีการจัดส่งอื่น',
      'form.validation.delivery_option.local':
        'ประเภทการจัดส่งที่คุณเลือกไม่สามารถใช้ได้กับเกาะในไต้หวัน โปรดเปลี่ยนที่อยู่จัดส่งเป็นเกาะเล็กรอบนอกหรือเลือกวิธีการจัดส่งอื่น',
      'form.validation.delivery_option.country':
        'ประเภทการจัดส่งที่คุณเลือกไม่สามารถใช้ได้กับประเทศนี้ โปรดเปลี่ยนที่อยู่จัดส่งหรือเลือกวิธีการจัดส่งอื่น',
      'form.validation.delivery_option.region':
        'ประเภทการจัดส่งที่คุณเลือกไม่สามารถใช้ได้กับภูมิภาคนี้ โปรดเปลี่ยนที่อยู่จัดส่งหรือเลือกวิธีการจัดส่งอื่น',
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'วันเกิดไม่ถูกต้อง ควรมีอายุมากกว่า {{minimumAgeLimit}} ปี',
      'form.validation.email.duplicate':
        'อีเมลล์นี้ได้ถูกใช้แล้ว กรุณาใช้อีเมลล์อื่น',
      'form.validation.email.hint': 'กรุณากรอกอีเมลที่ถูกต้อง',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',
      'form.validation.card.bin.error.unknow_scheme':
        'We cannot identify your credit card type. Please email shop owner for details.',
      'form.validation.calling_code': 'รหัสโทรศัพท์ระหว่างประเทศ (จำเป็น)',
      'form.validation.phone.required': '{{field_name}} จำเป็น',
      'form.validation.phone.error':
        '{{field_name}} ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง',
      'form.validation.mobile.error':
        '{{field_name}} is incorrect, please enter mobile phone',
      'dropdown.hint': 'โปรดเลือก',
      'redirect.go_home_in_seconds': 'กลับไปหน้าเพจหลักใน {{seconds}} วินาที',

      'session.signup.title': 'ลงทะเบียน',
      'session.terms.facebook': 'ลงทะเบียนด้วย Facebook',
      'session.signup.facebook': 'ล็อกอิน Facebook',
      'sessions.or': 'หรือ',
      'session.terms': 'หากดำเนินการต่อ คุณยอมรับต่อข้อกำหนดและเงื่อนไขของเรา',
      'session.signin.submit': 'ลงชื่อเข้าใช้งาน',
      'session.forgot_password': 'ลืมรหัสผ่าน',
      'session.signin.title': 'ลงชื่อเข้าใช้งาน',
      'session.signin.facebook': 'ล็อกอิน Facebook',
      'session.mobile_signup.check_mobile.title':
        'โปรดตรวจสอบหมายเลขโทรศัพท์มือถือว่าถูกต้องดังนี้ ข้อความสั้นกำลังส่งไปที่หมายเลขโทรศัพท์มือถือนี้',
      'session.mobile_signup.check_mobile.your_number':
        'หมายเลขโทรศัพท์มือถือของคุณ',
      'session.mobile_signup.check_mobile.your_number.hint':
        'โปรดระบุหมายเลขโทรศัพท์มือถือไต้หวัน (เริ่มต้นด้วย 09)',
      'session.mobile_signup.check_mobile.send_code': 'ส่งรหัสตรวจสอบมาให้ฉัน',
      'session.mobile_signup.check_mobile.go_back.1': 'คุณสามารถ',
      'session.mobile_signup.check_mobile.go_back.2': 'ย้อนกลับไป',
      'session.mobile_signup.check_mobile.go_back.3':
        'เพื่อแก้ไขหมายเลขโทรศัพท์มือถือหากผิดพลาด',
      'session.mobile_signup.input_code.hint':
        'โปรดระบุรหัสตรวจสอบที่คุณได้รับ',
      'session.mobile_signup.input_code.submit': 'ไป',
      'session.mobile_signup.input_code.resend.countdown':
        'ส่งข้อความรหัสตรวจสอบอีกครั้งมาที่โทรศัพท์ของฉัน ({{resendCountdown}} sec)',
      'session.mobile_signup.input_code.resend':
        'ส่งข้อความรหัสตรวจสอบอีกครั้งมาที่โทรศัพท์ของฉัน',
      'session.forget_passowrd.check_phone.error':
        'หมายเลขโทรศัพท์มือถือไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง',
      'session.mobile_center.check_phone.error':
        'หมายเลขโทรศัพท์มือถือถูกใช้ไปแล้ว',

      'user.quick_signup.title': 'สมัครสมาชิกตอนนี้!',
      'user.quick_signup.title.content': 'ไม่พลาดข้อเสนอสุดพิเศษ!',
      'user.quick_signup.promotion': 'ฉันต้องการรับอัปเดตและโปรโมชั่นล่าสุด',
      'user.quick_signup.policy': 'ฉันยอมรับต่อ',
      'user.quick_signup.policy.url_content':
        ' ข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัวของร้านค้า',
      'user.quick_signup.policy_with_age':
        'By proceeding, I agree to the<a href="{{ link }}"> shop\'s Terms of Use and Privacy Policy</a> and I am over {{ age }}',
      'user.quick_signup.submit': 'เข้าร่วมตอนนี้',
      'user.quick_signup.error.format': 'รูปแบบมีข้อผิดพลาด',
      'user.quick_signup.error.confirmed': 'หมายเลขโทรศัพท์นี้ถูกใช้ไปแล้ว ',
      'user.quick_signup.error.confirmed.url': 'ลงชื่อเข้าใช้งานตอนนี้',
      'user.quick_signup.validate.title': 'ดำเนินการลงทะเบียนต่อไป',
      'user.quick_signup.validate.success':
        'ขอบคุณที่สมัครเป็นสมาชิกของเรา！ <br/> คุณสามารถลงทะเบียนให้เสร็จสมบูรณ์ <br/>หรือ ',
      'user.quick_signup.validate.success.shop': 'เริ่มช้อปปิ้งตอนนี้',
      'user.quick_signup.validate.finish_hint':
        'ลงทะเบียนให้เสร็จสิ้นเพื่อตรวจดูและใช้เครดิตร้านค้าทันที่!',
      'user.quick_signup.validate.phone': 'ยืนยัน',
      'user.quick_signup.validate.send.sms': 'ส่งรหัสการยืนยัน',
      'user.quick_signup.validate.wrong.number':
        'หากหมายเลขโทรศัพท์ไม่ถูกต้อง โปรด',
      'user.quick_signup.validate.previous': 'ย้อนกลับไป',
      'user.quick_signup.validate.modify': ' และอัปเดต',
      'user.quick_signup.validate.hint.password': 'อย่างน้อย 8 ตัวอักษร',
      'user.quick_signup.validate.hint.send':
        'ส่งรหัสยืนยันแล้ว คุณสามารถส่งใหม่ได้หลังจาก {{count}} วินาที',
      'user.quick_signup.validate.hint.resend':
        'ยังไม่ได้รับรหัสตรวจสอบหรือ? โปรดคลิกที่นี่เพื่อส่งรหัสอีกครั้ง',
      'user.quick_signup.validate.submit': 'ทำการลงทะเบียนให้เสร็จสมบูรณ์',
      'user.quick_signup.validate.placeholder.sms': 'ใส่รหัสยืนยัน',
      'user.quick_signup.validate.placeholder.name': 'ชื่อ',
      'user.quick_signup.validate.placeholder.password': 'ใส่รหัสผ่าน',
      'user.sign_up.email_verification.dialog.title':
        'ส่งอีเมลยืนยันไปที่ {{mail}} เรียบร้อยแล้ว',
      'user.sign_up.email_verification.dialog.description':
        'โปรดยืนยันอีเมลตอนนี้และที่อยู่อีเมลใหม่จะได้รับการอัปเดต หากคุณไม่ได้รับ โปรดใส่อีเมลของคุณอีกครั้งและบันทึก',
      'user.sign_up.email_verification.dialog.confirm': 'ยืนยัน',
      'user.delivery_data.recipient_phone': 'เบอร์ติดต่อผู้รับ',

      'product.out_of_stock': 'หมด',
      'product.addon_products.label': 'รายการเสริม',
      'product.bundled_products.label': 'ราคาแบบแพ็ครวม',
      'product.bundle_group_products.label': 'ราคาแบบกลุ่มแพ็ครวม',
      'product.buyandget.label': 'Buy X Get Y',
      'product.addon_products.errors.main_product_out_of_stock':
        'มีสต็อกไม่เพียงพอสำหรับสินค้าหลัก',
      'product.addon_products.errors.reached_max_purchase_quantity':
        'จำกัด {{ message }} ต่อออเดอร์',
      'product.addon_products.errors.addon_product_out_of_stock':
        'มีของไม่เพียงพอสำหรับรายการเสริม',
      'product.addon_products.errors.larger_addon_product':
        'ปริมาณรายการเสริมไม่สามารถมากกว่าปริมาณรายการหลักได้',
      'product.addon_products.errors.out_of_stock':
        'มีของไม่เพียงพอสำหรับ {{title}}เสริม',
      'product.addon_products.tips.limit_exceed':
        'เมื่อคุณปรับปริมาณ ปริมาณของรายการเสริมต้องไม่เกินของผลิตภัณฑ์หลัก',
      'product.addon_products.add_to_cart.hint':
        'เพิ่มสินค้าลงในตะกร้าสินค้าของคุณแล้ว',
      'product.add_to_cart': 'หยิบใส่รถเข็น',
      'product.add_to_cart_fail': 'รถเข็นของคุณเต็มแล้ว!',
      'product.buy_now': 'ซื้อตอนนี้',
      'product.buy_now.preorder.hint': 'Now taking pre-orders',
      'product.buy_together': 'ซื้อพร้อมกัน',
      'product.preorder_limit.hint':
        'The purchase quantity includes both in-stock and preordered items.',
      'product.coming_soon': 'เร็ว ๆ นี้',
      'product.available_time_over': 'สิ้นสุดการขาย',
      'product.store_stock.check_stock': 'Check available pickup stores',
      'product.set.open_variation': 'View product details',

      'product.available_time_info.start.1': 'สินค้าพร้อมจำหน่าย',

      'member.center.load.more': 'เพิ่มเติม',
      'member.center.load.more.error': 'ไม่สามารถโหลดข้อมูลได้ โปรดลองอีกครั้ง',

      'wishlist.signin_register': 'เข้าสู่ระบบ / สมัครสมาชิก',
      'wishlist.messages.remove_error': 'ไม่สามารถลบรายการสิ่งที่อยากได้',

      orders: 'ออเดอร์',
      order: 'ออเดอร์',
      'orders.fields.billing_address': 'ที่อยู่เรียกเก็บเงิน',
      'orders.fields.order_number': 'หมายเลขออเดอร์',
      'orders.fields.order_date': 'วันที่',
      'orders.fields.order_status': 'สถานะ',
      'orders.fields.order_remarks': 'หมายเหตุออเดอร์',
      'orders.fields.customer_name': 'ชื่อลูกค้า',
      'orders.fields.product_subscription_period': '{{period}}',
      'orders.fields.status.temp': 'รอดำเนินการ',
      'orders.fields.status.pending': 'รอดำเนินการ',
      'orders.fields.status.confirmed': 'ยืนยันแล้ว',
      'orders.fields.status.shipped': 'จัดส่งแล้ว',
      'orders.fields.status.completed': 'สำเร็จแล้ว',
      'orders.fields.status.received': 'ได้รับแล้ว',
      'orders.fields.status.cancelled': 'ยกเลิกแล้ว',
      'orders.fields.status.returning': 'กำลังส่งคืน',
      'orders.fields.status.returned': 'ส่งคืนแล้ว',
      'orders.fields.preorder_item': 'รายการพรีออเดอร์',
      'orders.fields.customer': 'ลูกค้า',
      'orders.fields.customer.phone': 'เบอร์โทรศัพท์ลูกค้า',
      'orders.fields.billto': 'เรียกเก็บเงินที่',
      'orders.fields.payment_type': 'ประเภทการชำระเงิน',
      'orders.fields.payment_instructions': 'คำแนะนำการชำระเงิน',

      'orders.fields.order_delivery.arrived': 'สินค้าถึงที่หมายแล้ว',
      'orders.fields.order_delivery.collected': 'ได้รับสินค้าแล้ว',
      'orders.fields.order_delivery.pending': 'ยังไม่เสร็จสมบูรณ์',
      'orders.fields.order_delivery.returned': 'ส่งคืนสินค้าแล้ว',
      'orders.fields.order_delivery.returning': 'กำลังส่งคืนสินค้า',
      'orders.fields.order_delivery.shipped': 'จัดส่งสินค้าเรียบร้อย',
      'orders.fields.order_delivery.shipping': 'กำลังจัดส่งสินค้า',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        'ธุรกรรมล้มเหลว: ยอดออเดอร์สูงสุดสำหรับ CVS Payment คือ NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        'ธุรกรรมล้มเหลว: ยอดชำระเงินต่ำที่สุดสำหรับ CVS Payment คือ NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        'ธุรกรรมล้มเหลว: ยอดออเดอร์สูงสุดสำหรับ Barcode Payment คือ NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        'ธุรกรรมล้มเหลว: ยอดออเดอร์ต่ำสุดสำหรับ Barcode Payment คือ NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'ขีดจำกัดสูงสุดต่อการทำธุรกรรมโดยใช้ CVS คือ NTD20,000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'ขีดจำกัดสูงสุดต่อการทำธุรกรรมโดยใช้ Barcode คือ NTD20,000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'ขีดจำกัดสูงสุดต่อการทำธุรกรรมโดยใช้ WebATM คือ NTD30,000',
      'orders.fields.delivery_method': 'วิธีจัดส่ง',
      'orders.fields.delivery_description': 'คำอธิบาย',
      'orders.fields.delivery_fee': 'ค่าส่ง',
      'orders.fields.delivery_address': 'ที่อยู่จัดส่ง',
      'orders.fields.delivery_address.remarks': 'หมายเหตุการจัดส่ง',
      'orders.fields.accept_terms.validation.required':
        'คุณต้องยอมรับข้อกำหนดในการให้บริการเพื่อดำเนินการต่อ',
      'orders.fields.options.blacklist.error':
        'ผลิตภัณฑ์ของคุณมีวิธีการจัดส่งหรือการชำระเงินที่แตกต่างกัน โปรดเลือกวิธีที่ถูกต้องหรือส่งออเดอร์',
      'orders.show.message.thankyou.title': 'ขอบคุณสำหรับการสั่งซื้อ',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': 'ช้อปปิ้งต่อตอนนี้!',

      'orders.show.message.shipped.title': 'สินค้าของคุณได้รับการจัดส่ง',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': 'จำนวนคำสั่งซื้อ',
      'orders.payment_slip.order_info.instructions': 'คำแนะนำการชำระเงิน',
      'orders.payment_slip.order_info.read_more': 'ดูคำแนะนำฉบับเต็ม',
      'orders.payment_slip.upload_form.title':
        'หลังจากโอนเงินเสร็จแล้ว โปรดอัปโหลดรายละเอียดการโอนเงิน',
      'orders.payment_slip.upload_form.hint':
        'หลังจากยืนยันการชำระเงินแล้วเราจะจัดส่งสินค้าโดยเร็วที่สุด!',
      'orders.payment_slip.upload_form.notification':
        'หากต้องการรับการแจ้งเตือนการสั่งซื้อล่าสุดโปรดสมัครสมาชิก',
      'orders.payment_slip.upload_form.image_input.title':
        'อัปโหลดรายละเอียดการโอน',
      'orders.payment_slip.upload_form.image_input.description':
        'โปรดตรวจสอบว่ารูปภาพมีความชัดเจนและมีตัวเลข 5 หลักสุดท้ายของหมายเลขธุรกรรม เวลาในการโอน และจำนวนเงิน',
      'orders.payment_slip.upload_form.image_input.change':
        'คลิกเพื่อเปลี่ยนภาพ',
      'orders.payment_slip.upload_form.image_input.error':
        'ขนาดภาพต้องเล็กกว่า 10MB',
      'orders.payment_slip.upload_form.paid_time': 'เลือกเวลาชำระเงิน',
      'orders.payment_slip.upload_form.message':
        'กรอกหมายเหตุการชำระเงินของคุณ',
      'orders.payment_slip.upload_form.message.error':
        'คาต้องมีความยาวไม่เกิน {{ n }} ตัวอักษร',
      'orders.payment_slip.uploaded.alert':
        'ขอขอบคุณที่อัปโหลดรายละเอียดการโอนเงิน เราจะยืนยันโดยเร็วที่สุด!',
      'orders.payment_slip.uploaded.notification':
        'สมัครรับข้อมูลคำสั่งซื้อล่าสุด',
      'orders.payment_slip.uploaded.title':
        'ด้านล่างเป็นรายละเอียดการโอนเงินของคุณ',
      'orders.payment_slip.uploaded.paid_time': 'เวลาการชำระเงิน:',
      'orders.payment_slip.uploaded.upload_time': 'อัพเดทเวลาพิสูจน์:',
      'orders.payment_slip.uploaded.order_link': 'ดูใบสั่งซื้อ',
      'orders.payment_slip.uploaded_without_login.title':
        'ขอขอบคุณ! อัปโหลดรายละเอียดการโอนการชำระเงินสำหรับคำสั่งซื้อนี้แล้ว',
      'orders.payment_slip.uploaded_without_login.hint':
        'กรุณาเข้าสู่ระบบเพื่อดูรายละเอียด',
      'orders.payment_slip.footer':
        'ข้อมูลการชำระเงินที่คุณกรอกมีไว้สำหรับการตรวจสอบคำสั่งซื้อเท่านั้น และได้รับการเข้ารหัสอย่างปลอดภัย โดยคุณสามารถชำระเงินได้อย่างง่ายดาย',

      'product_review_comments.orders.back_to_my_account': '< กลับไปที่บัญชี',
      'product_review_comments.orders.comment': 'ความคิดเห็น',
      'product_review_comments.orders.comment_placeholder':
        '(ไม่จำเป็น) พึงพอใจกับการซื้อของคุณหรือไม่ กรุณาบอกความคิดเห็น',
      'product_review_comments.orders.orders_to_view': 'คำสั่งซื้อที่รีวิว',
      'product_review_comments.orders.order_id': 'IDคำสั่งซื้อ:',
      'product_review_comments.orders.purchased_date': 'วันที่สั่งซื้อ:',
      'product_review_comments.orders.rate_other_order':
        'ประเมินคำสั่งซื้ออื่น',
      'product_review_comments.orders.review': 'ความคิดเห็น (รีวิว)',
      'product_review_comments.orders.send': 'ส่ง',
      'product_review_comments.orders.empty.title': 'No products to reviews',
      'product_review_comments.orders.finished_review.title':
        'ขอบคุณสำหรับการแสดงความคิดเห็นของคุณ',
      'product_review_comments.orders.finished_review.desc.user_credit':
        'ยินดีด้วย คุณได้รับ {{ value }} เครดิตร้านค้า!',
      'product_review_comments.orders.finished_review.desc.member_point':
        'ยินดีด้วย คุณได้รับ {{ value }} คะแนนสมาชิก!',
      'product_review_comments.orders.max_length_of_characters':
        'ภายใน  2000 ตัวอักษร',
      'product_review_comments.orders.write_a_review': 'เขียนรีวิว',
      'product_review_comments.orders.upload_image': 'Upload image (maximum 9)',
      'product_review_comments.orders.completed_all_reviews':
        'ยินดีด้วย คุณรีวิวครบหมดแล้ว',
      'product_review_comments.orders.back_to_the_store': 'กลับไปที่ร้าน',

      'product.product_review.out_of_5_stars': 'ดาว',
      'product.product_review.rating_source_amazon': 'ประเมินจาก: Amazon',
      'product.product_review.rating_source_shopline': 'ประเมินจาก: ร้านนี้',
      'product.product_review.reviews': 'ความคิดเห็น (รีวิว)',
      'product.product_review.stars': 'ดาว',
      'product.product_review.no_review': 'ไม่มีรีวิวจากสินค้านี้',
      'product_review_entry.review_description_title':
        'พวกเรากำลังรอรับฟังความคิดเห็นของคุณอยู่',
      'product_review_entry.review_description_title.user_credit':
        'พวกเรากำลังรอรับฟังความคิดเห็นของคุณอยู่! รับเพิ่ม {{ value }} เครดิตร้านค้าหลังการแสดงความคิดเห็นของคุณ',
      'product_review_entry.review_description_title.member_point':
        'พวกเรากำลังรอรับฟังความคิดเห็นของคุณอยู่! รับเพิ่ม {{ value }} คะแนนสมาชิกหลังการแสดงความคิดเห็นของคุณ',
      'product_review_entry.review_description_content':
        'พึงพอใจกับการซื้อของคุณหรือไม่ กรุณาบอกความคิดเห็น',
      'product_review_entry.review_button_text': 'เขียนรีวิว',

      // customer cancel order section
      'orders.show.check_order.title': 'ตรวจสอบคำสั่ง',
      'orders.show.check_order.description':
        'คุณสามารถดูหรือยกเลิกคำสั่งซื้อได้ใน <a href="{{pagePath}}">ศูนย์สมาชิก > คำสั่งซื้อ</a>',
      'orders.show.customer_cancel_order.confirm.title':
        'คุณแน่ใจหรือไม่ว่าต้องการยกเลิก',
      'orders.show.customer_cancel_order.confirm.description.integrated':
        'ขออภัยที่จะเห็นคุณยกเลิกโปรดบอกเหตุผลและเราจะพยายามทำให้ประสบการณ์การช็อปปิ้งของคุณดีขึ้น! การคืนเงินจะเกิดขึ้นหลังจากการยกเลิก',
      'orders.show.customer_cancel_order.confirm.description.non_integrated':
        'ขออภัยที่พบคุณยกเลิกโปรดบอกเหตุผลและเราจะพยายามทำให้ประสบการณ์การช็อปปิ้งครั้งต่อไปของคุณดีขึ้น! อาจใช้เวลาสองสามวันในการประมวลผลการคืนเงินโปรดรออย่างอดทน',
      'orders.show.customer_cancel_order.confirm.loading.title': 'การประมวลผล',
      'orders.show.customer_cancel_order.confirm.loading.description':
        'โปรดรอสักครู่อย่าไปหรือกลับไปยังหน้าก่อนหน้า',
      'orders.show.customer_cancel_order.confirm.success.title':
        'ส่งคำขอยกเลิก',
      'orders.show.customer_cancel_order.confirm.success.description':
        'การแจ้งเตือนทางอีเมลจะถูกส่งหลังจากยกเลิกสำเร็จ',
      'orders.show.customer_cancel_order.confirm.failed.title':
        'โอ๊ะโอการยกเลิกล้มเหลว',
      'orders.show.customer_cancel_order.confirm.failed.description':
        'หากการลองใหม่ล้มเหลวโปรดติดต่อเราที่ "ร้านค้าและความคิดเห็นของลูกค้า" ด้านล่าง',
      'orders.show.customer_cancel_order.button.cancel': 'ยกเลิกคำสั่งซื้อ',
      'orders.show.customer_cancel_order.button.ok': 'ตกลง',
      // customer cancel order reasons
      'orders.show.customer_cancel_order.reasons.default':
        'โปรดเลือกเหตุผลในการยกเลิก',
      'orders.show.customer_cancel_order.reasons.change_mind': 'เปลี่ยนใจ',
      'orders.show.customer_cancel_order.reasons.expensive_price':
        'ราคาแพงกว่าที่อื่น',
      'orders.show.customer_cancel_order.reasons.repeat_purchase': 'ซื้อซ้ำ',
      'orders.show.customer_cancel_order.reasons.wait_too_long':
        'รอนานเกินไปสำหรับการจัดส่ง',
      'orders.show.customer_cancel_order.reasons.change_item':
        'ต้องการเปลี่ยนรายการซื้อ',
      'orders.show.customer_cancel_order.reasons.incorrect_customer_info':
        'ป้อนข้อมูลการจัดส่งหรือการชำระเงินที่ไม่ถูกต้อง',
      'orders.show.customer_cancel_order.reasons.other': 'อื่น ๆ',
      'orders.show.customer_cancel_order.reasons.other.placeholder':
        'กรุณาบอกเหตุผลกับเรา',
      'orders.show.customer_cancel_order.reasons.hint': 'เหตุผลในการยกเลิก',

      'orders.index.no_records.title': 'คุณไม่มีออเดอร์ใดๆ',
      'orders.index.no_records.description':
        'ดูเหมือนว่าคุณไม่เคยซื้อของจากร้านนี้',
      'orders.index.no_records.action': 'ไปช้อปปิ้ง',
      'orders.index.title': 'ออเดอร์',
      'orders.index.action.view': 'ดู',
      'address.fields.address.recipient_name': 'ชื่อผู้รับ',
      'address.fields.address': 'ที่อยู่',
      'address.fields.city': 'เมือง',
      'address.fields.postcode': 'รหัสไปรษณีย์',
      'address.fields.country': 'ประเทศ',

      'orders.fields.subtotal': 'ยอดรวมส่วนหนึ่ง',
      'orders.fields.total': 'ทั้งหมด',
      'order.billing_address.same': 'เหมือนกับที่อยู่จัดส่ง',

      'orders.coupons.label': 'รหัสคูปอง',
      'orders.coupons': 'ยินดีด้วย! คุณมีสิทธิ์รับส่วนลดดังต่อไปนี้:',
      'orders.coupons.placeholder': 'ใส่รหัสคูปอง',
      'orders.promotion_coupons.placeholder': 'ใส่รหัสคูปอง',

      'orders.coupons.apply': 'ใช้คูปอง',
      'orders.fields.order_discount': 'ใช้ส่วนลดแล้ว',
      'orders.fields.order_custom_discount': 'ส่วนลด (กำหนดเอง)',
      'orders.coupons.invalid': 'ขออภัย คูปองของคุณไม่ถูกต้อง',
      'orders.coupons.validation.minamount':
        'ขออภัย ออเดอร์ของคุณไม่ตรงกับค่าที่กำหนดสำหรับคูปองนี้',
      'orders.coupons.invalid.order':
        'โปรดลบรหัสคูปองที่ไม่ถูกต้องก่อนส่งออเดอร์',

      'orders.actions.title': 'จัดการสถานะออเดอร์',
      'orders.action.cancel': 'ยกเลิกออเดอร์',
      'orders.action.confirm': 'ยืนยันออเดอร์',
      'orders.action.ship': 'ทำเครื่องหมายเป็นส่งแล้ว',
      'orders.general.title': 'รายละเอียดออเดอร์',
      'orders.payment.title': 'รายละเอียดการชำระเงิน',
      'orders.delivery.title': 'รายละเอียดการจัดส่ง',
      'orders.products.title': 'รายละเอียดสินค้า',

      'orders.fields.accept_terms':
        'ถือว่าคุณยอมรับข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัวของร้านค้า',
      'orders.action.checkout': 'ส่งออเดอร์',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        'ชื่อผู้รับ',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        'ชื่อร้าน 7-11',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        'รหัสร้าน 7-11',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        'ชื่อผู้รับ',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'ชื่อร้าน Family Mart',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'รหัสร้าน Family Mart',
      'orders.fields.delivery_data.tw_ezship.location_code.hint':
        'ค้นหาร้านค้า',
      'orders.fields.delivery_data.tw_ezship.location_code.label':
        'รหัสร้านค้า',
      'orders.fields.delivery_data.tw_ezship.location_name.label':
        'ชื่อร้านค้า',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        'ชื่อผู้รับ',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': 'หมายเลขอ้างอิง',

      'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label':
        '7-11',
      'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label':
        'แฟมิลี่ มาร์ท',
      'orders.fields.delivery_data.sl_logistics_yto_store.store.label':
        'Cửa hàng',
      'orders.fields.delivery_data.sl_logistics_yto_store.city_list.error':
        'เกิดข้อผิดพลาดกับตัวเลือกการจัดส่งนี้โปรดรีเฟรชหน้าหรือ<a href="/cart" class="alert-highlight">กลับไปที่หน้าก่อนหน้าเพื่อเลือกตัวเลือกการจัดส่งอื่น</a>',
      'orders.fields.delivery_data.sl_logistics_yto_store.store_dropdown.error':
        'มีข้อผิดพลาดบางอย่างเกิดขึ้นกับที่อยู่ของคุณ กรุณาลองใหม่อีกครั้ง',

      'orders.fields.delivery_data.family_mart_freeze.error.locale_code':
        'จำเป็นต้องมีรหัสร้านค้า',

      'checkout_object.detail.close': 'Close',
      'checkout_object.detail.title': 'Checkout Amount Detail',

      'orders.promotion_type.promotion': 'โปรโมชั่น',
      'orders.promotion_type.reward_credit': 'เครดิตรางวัล',
      'orders.promotion_type.bundle_pricing': 'ราคาแบบแพ็ครวม',
      'orders.promotion_type.bundle_percentage': 'ราคาแบบแพ็ครวม',
      'orders.promotion_type.bundle_amount': 'ราคาแบบแพ็ครวม',
      'orders.promotion_type.bundle_gift': 'ราคาแบบแพ็ครวม',
      'orders.promotion_type.bundle_group': 'ราคาแบบกลุ่มแพ็ครวม',
      'orders.promotion_type.bundle_group_percentage': 'ราคาแบบกลุ่มแพ็ครวม',
      'orders.promotion_type.bundle_group_amount': 'ราคาแบบกลุ่มแพ็ครวม',
      'orders.promotion_type.bundle_group_gift': 'ราคาแบบกลุ่มแพ็ครวม',
      'orders.promotion_type.buyandget_free': 'Buy X Get Y',
      'orders.promotion_type.buyandget_pricing': 'Buy X Get Y',
      'orders.promotion_type.buyandget_percentage': 'Buy X Get Y',
      'orders.promotion_type.coupon': 'คูปอง',
      'orders.promotion_type.membership_offer': 'ข้อเสนอสำหรับสมาชิก',
      'orders.promotion_type.custom_discount': '"ส่วนลดกำหนดเอง"',
      'orders.promotion_type.member_point_redeem_gift': 'แคมเปญคะแนน',
      'orders.promotion_type.free_shipping': 'Free Shipping Promotion',
      'orders.promotion_type.credit_reward': 'Reward Credits',
      'orders.promotion_type.point_reward': 'Reward Points',
      'orders.promotion_type.earn_purchase_points': 'Earning Points',

      'orders.reward_credit.value': '{{ credit_value }} เครดิต',
      'orders.reward_credit.auto_reward':
        'เมื่อใช้จ่ายเกิน {{ credit_threshold }} รับเครดิต {{ credit_value }}',
      'orders.reward_credit.accumulated_auto_reward':
        'รับ {{ credit_value }} เครดิต ทุกการใช้จ่าย {{ credit_threshold }}',
      'orders.reward_credit.balance':
        'ซื้ออีกเพียง {{ auto_reward_balance }} เพื่อสิทธิประโยชน์',
      'orders.reward_credit.faq':
        'คุณจะได้รับเครดิตหลังจากชำระคำสั่งซื้อและสามารถนำไปใช้ในการซื้อครั้งต่อไปได้',

      'orders.fields.applied_user_credits': 'เครดิตร้านค้าที่ใช้',
      'orders.fields.applied_member_point_redeem_to_cash':
        'คะแนนส่วนลดที่ถูกใช้',
      'orders.fields.delivery_fee_free': 'ฟรี',
      'orders.fields.remaining_member_points_without_balance':
        'ถูกใช้ไป {{ applied }} คะแนน',
      'orders.fields.user_credits': 'เครดิต',
      'orders.fields.member_points': 'points',
      'orders.fields.rounding': 'ปัดเศษขึ้น/ลง',
      'orders.fields.credits_earned_after_paid':
        'เครดิตที่ได้รับหลังจากชำระเงิน',

      'orders.member_point.fields.redeemed': 'คะแนนที่ใช้แลกรับของขวัญ',
      'orders.member_point.unit': 'คะแนน',
      'orders.member_point.settled_after_order_completed':
        'Points earned after completed',
      'orders.member_point.pos_settled_content':
        'คะแนนจะได้รับหลังจาก {{ pending_days }} วัน หลังจากที่คำสั่งซื้อ POS เสร็จสมบูรณ์',
      'orders.member_point.settled_after_content':
        'คะแนนที่ได้รับ {{ pending_days }} วันหลังจากได้รับสินค้า',

      // Checkout page error message field name: '
      'order.customer_name': 'ชื่อลูกค้า',
      'order.email': 'อีเมล',
      'order.customer_email': 'ที่อยู่อีเมลของลูกค้า',
      'order.customer_phone': 'เบอร์ติดต่อลูกค้า',
      'order.delivery_data.recipient_name': 'ชื่อผู้รับ',
      'order.delivery_data.recipient_phone': 'เบอร์ติดต่อผู้รับ',
      'order.delivery_data.consignee_id_no':
        'หมายเลขประจำตัวประชาชนหรือหมายเลขหนังสือเดินทางของผู้รับ',
      'order.delivery_address.address_1': 'ที่อยู่',
      'order.delivery_address.city': 'เมือง',
      'order.delivery_address.postcode': 'รหัสไปรษณีย์',
      'order.delivery_address.state': 'เขต/รัฐ/จังหวัด',
      'order.delivery_data.location_code': 'รหัสร้านค้า',
      'order.delivery_data.location_name': 'ชื่อร้านค้า',
      'order.delivery_data.time_slot': 'เวลาจัดส่ง',
      'order.delivery_data.scheduled_delivery_date': 'วันที่มาถึง',
      'order.delivery_data.time_slot_key': 'ช่วงเวลาที่มาถึง',
      'order.invoice.carrier_number': 'ผู้จัดส่ง',
      'order.invoice.mailing_address': 'ที่อยู่',
      'order.invoice.tax_id': 'หมายเลขประจำตัวผู้เสียภาษี',
      'order.payment_data.holdername': 'ชื่อและนามสกุลผู้ถือบัตร',
      'order.payment_data.expiry_date': 'วันหมดอายุ',
      'order.payment_data.cvc': 'CVC',
      'order.payment_data.credit_card_number': 'หมายเลขบัตรเครดิต',

      'lock_inventory.locked_quantity_unit': 'ชิ้น',

      'delivery.sfexpress.district': 'เขต SF Express',
      'delivery.sfexpress.store': 'สถานที่ SF Express',
      'delivery.sfexpress.locker_location': 'ที่ตั้ง Locker',
      'delivery.sfexpress.ef_locker': 'EF Locker',
      'delivery_address.region.hk': 'ภูมิภาค',
      'delivery_address.district.hk': 'เขต',
      'delivery_address.region.tw': 'รัฐ/เมือง',
      'delivery_address.district.tw': 'เขต',
      'save_fields.customer_info.birthday': 'วันเกิดลูกค้า',

      'delivery_address.province.vn': 'Province',
      'delivery_address.district.vn': 'District',
      'delivery_address.ward.vn': 'Ward',
      'delivery_address.province.th': 'จังหวัด',
      'delivery_address.district.th': 'เขต/อำเภอ',
      'delivery_address.ward.th': 'เขต',
      'delivery_address.street': 'ถนน',
      'delivery_address.store': '{{ station_name }} ร้านสะดวกซื้อ',

      'delivery_options.fields.delivery_type': 'ประเภทการจัดส่ง',
      'delivery_options.fields.delivery_types.pickup': 'รับเอง',
      'delivery_options.fields.delivery_types.local': 'เฉพาะที่',
      'delivery_options.fields.delivery_types.email': 'อีเมล',
      'delivery_options.fields.delivery_types.international': 'ระหว่างประเทศ',
      'delivery_options.fields.delivery_types.custom': 'กำหนดเอง',
      'delivery_options.fields.delivery_types.tw_simple_711':
        'รับที่ 7-11 (เฉพาะใต้หวัน)',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'รับที่ Family Mart (เฉพาะใต้หวัน)',
      'delivery_options.fields.select_date': 'วันที่มาถึง',

      'ezship.error.invalid_input':
        'มีข้อมูลอย่างน้อยหนึ่งช่องไม่ถูกต้องหรือหายไป',
      'ezship.error.account_not_exist': 'ไม่มีบัญชี ezShip กรุณาติดต่อผู้ขาย',
      'ezship.error.no_permission': 'บัญชี ezShip มีสิทธิ์เข้าถึงไม่เพียงพอ',
      'ezship.error.no_easybag': 'บัญชี ezShip ไม่มี Easybag หรือ Minibag',
      'ezship.error.invalid_store': 'Pickup Store ไม่ถูกต้อง',
      'ezship.error.invalid_amount':
        'จำนวนไม่ถูกต้อง ยอดสูงสุดที่สามารถใช้ ezShip คือ TWD 6000 ต่อออเดอร์',
      'ezship.error.invalid_email': 'อีเมลไม่ถูกต้อง',
      'ezship.error.invalid_mobile': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
      'ezship.error.invalid_name': 'ชื่อผู้รับไม่ถูกต้อง',
      'ezship.error.system_error':
        'ระบบ ezShip มีข้อผิดพลาด โปรดเลือกวิธีการจัดส่งอื่นหรือลองอีกครั้งในภายหลัง',

      'payments.fields.types.paypal': 'บัตรเครดิตหรือ Paypal',
      'payments.fields.types.paypal_express': 'บัตรเครดิตหรือ Paypal',
      'payments.fields.types.credit_card': 'บัตรเครดิต',
      'payments.fields.types.bank_transfer': 'โอนเงินผ่านธนาคาร',
      'payments.fields.types.cash_on_delivery': 'ชำระเงินสดปลายทาง',
      'payments.fields.types.free_checkout': 'ชำระเงินฟรี',
      'payments.fields.types.custom': 'กำหนดเอง',
      'payments.fields.types.allpay_barcode': 'บาร์โค้ดผ่าน Allpay',
      'payments.fields.types.allpay_credit': 'บัตรเครดิตผ่าน Allpay',
      'payments.fields.types.allpay_cvs': 'CVS ผ่าน Allpay',
      'payments.fields.types.allpay_webatm': 'WebATM ผ่าน Allpay',
      'payments.fields.types.asiapay': 'บัตรเครดิตผ่าน Asiapay',
      'payments.fields.types.prizm': 'บัตรเครดิต Visa / Master',
      'payments.fields.types.esun': 'บัตรเครดิต Esun',

      'payment.cc.expirydate': 'วันหมดอายุ',
      'payment.cc.holdername': 'เจ้าของบัตร',
      'payment.cc.number': 'หมายเลขบัตรเครดิต (Visa / Master)',
      'payment.cc.cvc': 'CVC',

      'payment.cc.expirydate.placeholder': 'MM/YY',
      'payment.cc.holdername.placeholder': 'เจ้าของบัตร',
      'payment.cc.holdername.hint': 'เหมือนกับชื่อและนามสกุลบนบัตร',
      'payment.cc.number.placeholder': 'หมายเลขบัตรเครดิต (ไม่เว้นวรรค)',
      'payment.cc.cvc.placeholder': 'CVC',
      'payment.cc.cvc.hint': 'รหัสความปลอดภัย 3 หลัก จะอยู่ด้านหลังบัตรของคุณ',
      'payment.action.confirm': 'ยืนยัน',
      'payment.cc.update.instructions':
        'โปรดป้อนข้อมูลบัตรเครดิตของคุณด้านล่างเพื่ออัปเดต',
      'payment.cc.fields.accept_terms':
        'ฉันยอมรับข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัว',
      'payment.cc.fields.accept_terms.validation.required':
        'คุณต้องยอมรับข้อกำหนดในการให้บริการเพื่อดำเนินการต่อ',
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',

      'payments.fields.types.ezship': 'ชำระบน Store Pickup ผ่าน ezShip',

      cart: 'ตะกร้าสินค้า',
      'cart.title': 'ตะกร้าสินค้า',
      'cart.clean': 'ชำระเงินตอนนี้!',
      'cart.item.remove': 'ลบออก',
      'cart.items.remove': 'ลบออก',
      'cart.checkout': 'ดำเนินการสั่งซื้อ',
      'cart.over_limit':
        'ตะกร้าสินค้ามีสินค้าเกินจำนวนสูงสุด <span class="limit-number">{{limit_number}}</span> รายการ โปรดชำระเงินก่อนเพิ่มรายการใหม่ คุณยังสามารถเข้าสู่ระบบ เพื่อเพิ่มรายการเหล่านี้ในรายการสิ่งที่อยากได้ของคุณ!',
      'cart.over_limit.login':
        'ตะกร้าสินค้ามีสินค้าเกินจำนวนสูงสุด <span class="limit-number">{{limit_number}}</span> รายการ โปรดตรวจสอบก่อนที่จะเพิ่มสินค้าใหม่ คุณสามารถเพิ่มสินค้านี้ในรายการสิ่งที่อยากได้ของคุณ!',
      'cart.empty': 'ตะกร้าสินค้าของคุณว่าง',
      'cart.empty.description': 'เพิ่มสินค้าลงในตะกร้าเพื่อดำเนินการต่อ',
      'cart.empty.continue': 'ช้อปปิ้งต่อตอนนี้!',

      'cart.promotion.has_items_selected':
        'ได้เลือก <b>{{quantity}}</b> รายการแล้ว',
      'cart.promotion.bundle_group.has_items_selected':
        'กลุ่ม A สินค้าถูกเลือก <b style="color: #f05c53">{{red_quantity}}</b> รายการ {{red_reminder}} กลุ่ม B สินค้าถูกเลือก <b style="color: #1ba462">{{green_quantity}}</b> รายการ {{green_reminder}}{{applied_reminder}}',
      'cart.promotion.bundle_group.has_items_selected.mobile':
        'กลุ่ม A <b style="color: #f05c53">{{red_quantity}}</b> รายการ กลุ่ม B <b style="color: #1ba462">{{green_quantity}}</b> รายการ',

      'cart.promotion_reminder.separator': ' ',
      'cart.promotion_reminder.group_red':
        '<span class="bundle-group-label bundle-group-red">กลุ่ม A</span> เพิ่มอีก {{gap}} รายการ',
      'cart.promotion_reminder.group_green':
        '<span class="bundle-group-label bundle-group-green">กลุ่ม B</span> เพิ่มอีก {{gap}} รายการ',
      'cart.promotion_reminder.group_gap': ' (เพิ่มอีก {{gap}} รายการ)',
      'cart.promotion_reminder.item': 'ซื้อเพิ่มอีก {{gap}} ',
      'cart.promotion_reminder.amount': 'ซื้อเพิ่มอีก {{gap}} ',
      'cart.promotion_reminder.multiple_step': 'เพื่อประหยัดมากขึ้น',
      'cart.promotion_reminder.multiple_step.reward_campaign': 'to save more!',
      'cart.promotion_reminder.stackable': ' ซื้อมากขึ้นเพื่อประหยัดมากขึ้น',
      'cart.promotion_reminder.applied.free_shipping': 'ฟรีค่าบริการจัดส่ง',
      'cart.promotion_reminder.applied.gift': 'รับฟรีชองขวัญ',
      'cart.promotion_reminder.applied.discount_prefix': ' ',
      'cart.promotion_reminder.applied.discount': 'เพลิดเพลินกับข้อเสนอ ',
      'cart.promotion_reminder.applied.reward_campaign':
        'เพลิดเพลินกับข้อเสนอ ',
      'cart.promotion_reminder.applied_highest.free_shipping':
        'ฟรีค่าบริการจัดส่ง',
      'cart.promotion_reminder.applied_highest.gift': 'รับฟรีชองขวัญ',
      'cart.promotion_reminder.applied_highest.discount':
        'เพลิดเพลินกับข้อเสนอ',
      'cart.promotion_reminder.applied_highest.reward_campaign':
        'เพลิดเพลินกับข้อเสนอ',
      'cart.promotion_reminder.discount_target.free_shipping':
        'เพื่อรับบริการจัดส่งฟรี',
      'cart.promotion_reminder.discount_target.gift': 'เพื่อรับฟรีชองขวัญ',
      'cart.promotion_reminder.discount_target.reward_campaign':
        'เพื่อรับสิทธิพิเศษ',
      'cart.promotion_reminder.discount_target.discount': 'เพื่อรับสิทธิพิเศษ',
      'cart.lock_cart_sc_product.description':
        'สินค้านี้ถูกสั่งซื้อจากไลฟ์สตรีม/โพสต์การขาย จำนวนของสินค้าไม่สามารถแก้ไขได้',
      'cart.lock_cart_sc_product.remove_hint':
        'สินค้านี้ถูกสั่งซื้อจากไลฟ์สตรีม/โพสต์การขาย ไม่สามารถลบได้',
      'cart.affiliate_code.apply_success': 'Affiliate Applied',
      'cart.coupon_code.apply_success': 'Coupon Applied',

      'checkout.instructions': 'รายละเอียดออเดอร์',
      'checkout.fields.email': 'อีเมลลูกค้า',
      'checkout.fields.email.hint':
        'กรุณากรอกที่อยู่อีเมลที่ถูกต้องของคุณ เนื่องจากการยืนยันออเดอร์จะส่งไปยังอีเมลของคุณ',
      'checkout.fields.phone.hint':
        'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณเพื่อรับสถานะการจัดส่งทาง SMS',
      'checkout.fields.phone.invalid': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
      'checkout.fields.email.placeholder': 'อีเมล',
      'checkout.fields.remarks': 'หมายเหตุถึงร้านค้า',
      'checkout.fields.remarks.placeholder': 'ระบุหมายเหตุออเดอร์สำหรับร้านค้า',
      'checkout.fields.delivery_method': 'วิธีจัดส่ง',
      'checkout.fields.out_of_stock':
        'ขออภัย มีของไม่เพียงพอ โปรดลบสินค้าออกจากตะกร้าและชำระเงินอีกครั้ง',
      'checkout.fields.phone': 'หมายเลขโทรศัพท์ติดต่อ',
      'checkout.fields.phone.placeholder': 'ป้อนหมายเลขโทรศัพท์ติดต่อของคุณ',
      'checkout.fields.add_new': 'เพิ่มใหม่...',
      'checkout.fields.select': 'เลือกหนึ่งอัน',

      'checkout.delivery_option.title': 'ที่อยู่จัดส่ง',
      'checkout.delivery_option.out_of_stock':
        'สินค้าบางรายการหมด ไม่สามารถเลือกร้านนี้ได้ โปรดลองเลือกร้านอื่น',
      'checkout.instalment_amount': 'ประมาณ {{amount}} x {{period}} การผ่อ',
      'checkout.labels.promotion.free_shipping': '',
      'checkout.labels.promotion.coupon': '',
      'checkout.labels.promotion.promotion': '',
      'checkout.labels.promotion.membership': '',

      'checkout.payment_method.title': 'การชำระเงิน',

      'checkout.discount.applied_promotions': 'โปรโมชั่นที่ใช้',
      'checkout.discount.applied_coupons': 'คูปองที่ใช้',

      'checkout.promotion.tags.promotion': 'โปรโมชั่น',
      'checkout.promotion.tags.coupon': 'คูปอง',
      'checkout.promotion.tags.membership': 'ข้อเสนอสำหรับสมาชิก',
      'checkout.promotion.tags.free_shipping': 'ส่งฟรี',
      'checkout.promotion.tags.subscription_promotion':
        'โปรโมชันสำหรับการสมัครสมาชิกซื้อสินค้าแบบประจำ',

      'checkout.payment_condition.title':
        'เพิ่ม {{difference}} เพื่อให้เป็นไปตามเงื่อนไขของตัวเลือกการชำระเงิน!',
      'checkout.payment_condition.description':
        'ก่อนดำเนินการชำระเงิน โปรดกลับไปที่ตะกร้าสินค้าเพื่อเลือกวิธีการชำระเงินอื่นหรือเลือกซื้อสินค้าต่อ',
      'checkout.payment_condition.continue_shopping': 'ดำเนินการซื้อสินค้าต่อ',
      'checkout.payment_condition.hint':
        'คำสั่งซื้อนี้ต้องเป็นไปตามเงื่อนไขยอดสั่งซื้อขั้นต่ำเพื่อดำเนินการชำระเงิน',
      'checkout.payments.exist.order.tip':
        'You have already placed order <a href="{{ duplicateOrderLink }}">{ {{order}} }</a>. If you wish to complete the checkout, please click “Place Order”.',

      'checkout.payments.apple_pay.not_supported':
        'อุปกรณ์ไม่รองรับ Apple Pay โปรดตรวจสอบสิ่งต่อไปนี้<ul><li>กรุณาล็อกอินบนเบราว์เซอร์ด้วย Apple Account ของคุณ</li>' +
        '<li>Please ADD the payment method to your Apple Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payments.google_pay.not_supported':
        'อุปกรณ์ไม่รองรับ Google Pay โปรดตรวจสอบสิ่งต่อไปนี้<ul><li>กรุณาล็อกอินบนเบราว์เซอร์ด้วยบัญชี Google ของคุณ</li>' +
        '<li>Please ADD the payment method to your Google Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payment_failed': 'ธุรกรรมนี้ล้มเหลว โปรดลองอีกครั้ง',
      'checkout.payment_failed.atome.minamount':
        'ยอดคำสั่งซื้อไม่ถึงยอดชำระเงินขั้นต่ำสำหรับวิธีการชำระเงินนี้ โปรดทำการแก้ไขรายละเอียดในตะกร้าสินค้าหรือเปลี่ยนเป็นวิธีการชำระเงินอื่น',
      'checkout.cart_over_limit_error':
        'จำนวนสินค้าสูงสุดคือ 100 ชิ้น กรุณาปรับจำนวนและดำเนินการซื้อสินค้าอีกครั้ง',

      'checkout.auto_fill.popup.title': 'ป้อนโทรศัพท์ของคุณเพื่อชำระเงินด่วน',
      'checkout.auto_fill.popup.continue': 'ดำเนินการต่อ',
      'checkout.auto_fill.popup.error': 'กรุณากรอกรูปแบบที่ถูกต้อง',
      'checkout.auto_fill.popup.cancel': 'ยกเลิก',
      'checkout.auto_fill.toast.error':
        'หากไม่มีบันทึกการสั่งซื้อล่าสุด ระบบจะไม่นำเข้าข้อมูลการชำระเงินด่วน',

      'address.fields.recipient_name': 'ชื่อผู้รับ',
      'address.fields.address.city': 'เมือง',
      'address.fields.address.state': 'ภูมิภาค/รัฐ/จังหวัด',
      'address.fields.address.postcode': 'รหัสไปรษณีย์ (ถ้ามี)',
      'address.fields.address.country': 'ประเทศ',
      'address.fields.address.remarks': 'หมายเหตุ',

      'messages.form.send': 'ส่ง',
      'action.add_photo': 'เพิ่มรูปภาพ',
      'action.send': 'ส่ง',
      'action.load_earlier': 'โหลดก่อนหน้า',
      'action.load_more': 'โหลดเพิ่มเติม',

      'users.fields.name': 'ชื่อและนามสกุล',
      'users.fields.email': 'อีเมล',
      'users.fields.mobile_phone_or_email': 'อีเมลหรือหมายเลขโทรศัพท์มือถือ',
      'users.fields.password': 'รหัสผ่าน',
      'users.fields.name.placeholder': 'ชื่อและนามสกุล',
      'users.fields.email.placeholder': 'อีเมล',
      'users.fields.password.placeholder': 'รหัสผ่าน',
      'users.fields.callingCode': 'รหัสประเทศ',
      'users.fields.callingCodeHint': 'โปรดเลือกรหัสประเทศ',
      'users.save.success': 'บันทึกการเปลี่ยนแปลงของคุณแล้ว',
      'users.save.email_verification.success':
        'บันทึกการเปลี่ยนแปลงของคุณแล้ว ที่อยู่อีเมลจะได้รับการอัปเดตหลังจากการยืนยันตัวตนเสร็จสมบูรณ์',
      'users.save.email_verification.send': 'อีเมลยืนยันถูกส่งเรียบร้อยแล้ว',
      'users.add.phone': 'Add contact phone',
      'users.add.phone.maximum':
        'คุณสามารถเพิ่มหมายเลขโทรศัพท์ได้สูงสุด 5 หมายเลข',
      'users.error.phone.format': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
      'users.error.phone.required': 'จำเป็นต้องมีหมายเลขโทรศัพท์',
      'users.add.delivery_address': 'เพิ่มที่อยู่ใหม่',
      'users.add.delivery_address.maximum':
        'คุณสามารถเพิ่มที่อยู่ได้สูงสุด 5 รายการ',
      'users.error.not_save_mobile_phone':
        'โปรดยืนยันหมายเลขโทรศัพท์นี้ก่อนบันทึกการเปลี่ยนแปลง',
      'users.error.no_changes': 'ไม่มีการเปลี่ยนแปลงที่จะบันทึก',
      'users.error.maxlength': ' ยาวเกินไป',
      'users.signup.male': 'ชาย',
      'users.signup.female': 'หญิง',
      'users.signup.other': 'ไม่เปิดเผย',
      'users.signup.email': 'ลงทะเบียนด้วยอีเมล',
      'users.signup.mobile_phone': 'ลงทะเบียนด้วยเบอร์มือถือ',
      'users.signup.unconfirmed':
        'ยินดีต้อนรับอีกครั้ง! การเข้าสู่บัญชีจำเป็นต้องทำการยืนยันตัวตน เพื่อปกป้องบัญชีของคุณ ส่งอีเมลยืนยันไปที่ {{email}} แล้ว โปรดตรวจสอบกล่องจดหมายของคุณ ขอบคุณ!',
      'users.signup.verification.hint.send':
        'ส่งอีเมลยืนยันแล้ว คุณสามารถส่งใหม่ได้หลังจาก {{count}} วินาที',
      'users.einvoice_carrier': 'สรุปใบแจ้งหนี้อิเล็กทรอนิกส์ในบัญชีของคุณ',
      'users.edit_member_info': 'แก้ไขข้อมูลสมาชิก',
      'users.membership.member_info.title':
        'รางวัลการตั้งค่าข้อมูลสมาชิกเสร็จสมบูรณ์',
      'users.membership.member_info.hint':
        'กรอกข้อมูลสมาชิกดังต่อไปนี้: {{fields}} คุณจะได้รับ {{rewards}} หลังจากกรอกข้อมูลสมาชิกเรียบร้อยแล้ว',
      'users.membership.member_info.sent':
        'หลังจากการตรวจสอบข้อมูลเสร็จสิ้น โบนัสการกรอกประวัติสมาชิกจะออกให้!',
      'users.membership.member_info.symbol': ', ',
      'users.membership.member_info.name': 'ช่ือ',
      'users.membership.member_info.email': 'อีเมล',
      'users.membership.member_info.mobile': 'หมายเลขโทรศัพท์',
      'users.membership.member_info.gender': 'เพศ',
      'users.membership.member_info.birthday': 'วันเกิด',
      'users.membership.member_info.user_credits':
        'เครดิตร้านค้า {{count}} คะแนน',
      'users.membership.member_info.member_points':
        'คะแนนสมาชิก {{count}} คะแนน',
      'users.membership.member_info.coupons': 'คูปอง {{count}} ใบ',
      'users.membership.expiry_date': 'วันหมดอายุสมาชิก',
      'users.membership.offer.discount': 'ข้อเสนอสำหรับสมาชิก',
      'users.membership.upgrade.next_tier': 'อัปเกรดเป็น {{tier_name}}',
      'users.membership.upgrade.next_tier.discount':
        ', รับส่วนลด {{discount}}%',
      'users.membership.spending_within_months':
        'อัปเกรดด้วยยอดซื้อรวมทั้งหมดมากกว่า {{spending}} ภายใน {{months}} เดือน',
      'users.membership.spending_single_purchase':
        'อัปเกรดด้วยยอดซื้อครั้งเดียวมากกว่า {{spending}}',
      'users.membership.extension.condition': 'เงื่อนไขการต่ออายุสมาชิก',
      'users.membership.extension.status': 'Membership Extend Status',
      'users.membership.extend_valid_period':
        '{{ type === "within_interval" ? "ยอดซื้อรวมทั้งหมด" : "ยอดซื้อครั้งเดียว" }} มากกว่า {{spending}} ในระยะเวลาที่เป็นสมาชิก ภายใน {{months}} เดือน',
      'users.membership.all_tiers': 'ดูรายการระดับสมาชิกทั้งหมด',
      'users.membership.tier.extend': 'Eligible to extend <b>{{tier}}</b>?',
      'users.membership.tier.upgrade.to_next_level.single':
        'อัปเกรดไปยังระดับสมาชิกขั้นถัดไป?',
      'users.membership.tier.upgrade.condition.continue': 'เลือกซื้อสินค้าต่อ',
      'users.membership.tier.upgrade.completed': 'สามารถอัพเกรดได้',
      'users.membership.tier.expire.date.description':
        'การอัปเกรดระดับสมาชิกจะถูกตรวจสอบตอนเที่ยงคืน สมาชิกจะไม่ถูกอัปเกรดระดับสมาชิกหากคำสั่งซื้อถูกยกเลิก/คืนเงิน',
      'users.membership.tier.expire.date.auto_downgrade.description':
        'การอัปเกรดระดับสมาชิกจะถูกตรวจสอบตอนเที่ยงคืน สมาชิกจะไม่ถูกอัปเกรดระดับสมาชิกหากคำสั่งซื้อถูกยกเลิก/คืนเงิน',
      'users.membership.tier.extension.date.description':
        'Membership extension will not be effective if order has been cancelled/refunded.',
      'users.membership.all_tiers.description':
        'หากไม่มีการต่ออายุสมาชิกจะถูกลดระดับเมื่อถึงวันหมดอายุสมาชิก',
      'users.membership.tier.upgrade.condition': 'เงื่อนไขอัปเกรดระดับสมาชิก:',
      'users.membership.tier.upgrade.condition.dollar':
        'ปัจจุบันยังไม่มียอดซื้อครั้งเดียวมากกว่า {{dollar}}',
      'users.membership.tier.expire.date': 'ระยะเวลาสมาชิก:',
      'users.membership.tier.shopping_discount':
        'สมาชิกได้รับส่วนลด {{discount}}%',
      'users.membership.tier.extend.condition': 'เงื่อนไขต่ออายุสมาชิก:',
      'users.membership.tier.extend.single_purchase':
        'No single purchase over {{dollar}} within {{month}}-month membership valid period.',
      'users.membership.tier.extend.single_purchase.can_extend':
        'You have a single purchase over {{dollar}} within {{month}}-month membership valid period. Membership extension will be effective on expiry date.',
      'users.membership.tier.extend.top_level.single_purchase':
        'No single purchase over {{dollar}} within {{month}}-month membership valid period.',
      'users.membership.tier.extend.top_level.single_purchase.can_extend':
        'You have a single purchase over {{dollar}} within membership valid period. Congrats! Membership extension will be effective on expiry date.',
      'users.membership.tier.extend.within_interval':
        'Your total purchase within {{month}}-month membership valid period is {{spending}}. {{dollar}} more to extend.',
      'users.membership.tier.extend.within_interval.can_extend':
        'Your total purchase within {{month}}-month membership valid period is over {{dollar}}. Extension will be effective on expiry date.',
      'users.membership.tier.extend.top_level.within_interval':
        '{{dollar}} more to extend',
      'users.membership.tier.extend.top_level.within_interval.can_extend':
        'Congrats! Extension will be effective on expiry date.',
      'users.member.normal-level': 'ทั่วไป',
      'users.membership.max_level_tier':
        'ยินดีด้วย! คุณอยู่ในระดับสมาชิกสูงสุดแล้ว',

      'membership.profile.subscriptions.orders.update.status':
        'อัปเดตออเดอร์ของฉัน',
      'membership.profile.subscriptions.orders.comments.new':
        'ความคิดเห็นใหม่จากผู้ขายในออเดอร์ของฉัน',
      'membership.profile.subscriptions.messages.new': 'ข้อความใหม่จากผู้ขาย',
      'membership.profile.subscriptions.marketing.news':
        'การอัปเดตและโปรโมชั่นร้านค้า',
      'membership.profile.subscriptions.none': 'ไม่มี',
      'membership.profile.mobile.number.verified':
        'การยืนยันหมายเลขโทรศัพท์เสร็จเรียบร้อย และจะแทนที่หมายเลขโทรศัพท์ที่คุณบันทึกไว้ก่อนหน้านี้',
      'membership.profile.check.code.error':
        'รหัสตรวจสอบไม่ถูกต้อง! โปรดใส่รหัสตรวจสอบอีกครั้งหรือส่งข้อความรหัสตรวจสอบอีกครั้ง',
      'membership.profile.verification_hint':
        'หากคุณเปลี่ยนที่อยู่อีเมลจำเป็นต้องมีการยืนยันหลังจากบันทึก อีเมลจะอัปเดตเป็น {{unconfirmed_email}} หลังจากการยืนยันเสร็จสิ้น',

      'coupon.title': 'คูปอง',
      'coupon.valid': 'ถูกต้อง',
      'coupon.invalid': 'ไม่ถูกต้อง',
      'coupon.valid_until':
        'สิ้นสุดวันที่  <span class="coupon-period-time"><div class="coupon-period-tip">แสดงเวลาในพื้นที่ของคุณ<br/>(GMT{{timeZone}})</div> {{time}} </span>',
      'coupon.one_time': 'หนึ่งครั้ง',
      'coupon.first_shoppers': 'ผู้ซื้อ {{number}} ท่านแรกเท่านั้น',
      'coupon.reach_limit': 'ถึงปริมาณจำกัด',
      'coupon.expired': 'หมดอายุ',
      'coupon.no_valid_coupon': 'ไม่มีคูปองที่ถูกต้อง',
      'coupon.no_invalid_coupon': 'ไม่มีคูปองไม่ถูกต้อง',
      'coupon.select_coupon': 'เลือกคูปอง',
      'coupon.confirm': 'ยืนยัน',
      'coupon.first_purchase_only': 'คำสั่งซื้อแรก',
      'coupon.comingSoon': 'เร็วๆนี้',
      'coupon.retail_store_only': 'ร้านค้าปลีกเท่านั้น',
      'coupon.online_store_only': 'ร้านค้าออนไลน์เท่านั้น',
      'coupon.online_and_selected_retail_store_only':
        'ร้านค้าออนไลน์/ร้านค้าปลีกที่เลือกเท่านั้น',
      'coupon.selected_retail_store_only': 'ร้านค้าปลีกที่เลือกเท่านั้น',
      'coupon.selected_retail_store_list': 'รายชื่อร้านค้าปลีกที่เลือก',
      'coupon.login_to_receive': 'เข้าสู่ระบบเพื่อรับคูปอง',
      'coupon.login_or_register': 'โปรดคลิกที่ปุ่มเพื่อเข้าสู่ระบบหรือสมัคร',
      'coupon.usage_limit': 'Cการใช้คูปองถึงจำนวนสูงสุดแล้ว',
      'coupon.already_taken': 'คุณได้รับคูปองนี้ไปแล้ว',
      'coupon.is_expired': 'คูปองหมดอายุแล้ว',
      'coupon.not_eligible': 'ไม่สามารถใช้กับคูปองได้',
      'coupon.login': 'เข้าสู่ระบบ',
      'coupon.close': 'ปิด',
      'coupon.success': 'สำเร็จ',
      'coupon.congratulation': 'ยินดีด้วย!',
      'coupon.view': 'ดูคูปอง',

      'store_credits.title': 'เครดิตร้านค้า',
      'store_credits.credit_balance': 'ยอดเครดิต',
      'store_credits.credit_history': 'ประวัติเครดิต',
      'store_credits.fields.date': 'วันที่',
      'store_credits.fields.remarks': 'เหตุผลที่เปลี่ยนแปลงเครดิต',
      'store_credits.fields.value': 'การเคลื่อนย้ายเครดิต',
      'store_credits.fields.expiry_date': 'วันหมดอายุ',
      'store_credits.fields.balance': 'ยอดเครดิต',
      'store_credits.fields.order_prefix': 'ใช้เครดิตในออเดอร์',
      'store_credits.fields.welcome_credit': 'เครดิตต้อนรับ',
      'store_credits.fields.member_info_quick_completion_credit':
        'เครดิตรางวัลเมื่อตั้งค่าข้อมูลสมาชิกเสร็จสมบูรณ์',
      'store_credits.fields.birthday_credit': 'เครดิตวันเกิด',
      'store_credits.fields.user_credit_expired': 'เครดิตหมดอายุ',
      'store_credits.fields.never_expires': 'ไม่มีวันหมดอายุ',
      'store_credits.fields.never_expires_point': 'คะแนนไม่มีวันหมดอายุ',
      'store_credits.fields.display.never_expired': 'ไม่มีวันหมดอายุ',
      'store_credits.fields.auto_reward_prefix': 'Order ',
      'store_credits.fields.auto_reward': ' gets reward credits',
      'store_credits.fields.revert_credit':
        'เครดิตคืนกลับจากออเดอร์ที่ถูกยกเลิก',
      'store_credits.fields.revert_credit.return':
        'เรียกคืนเครดิตจากคำสั่งซื้อที่ส่งคืน',
      'store_credits.fields.revert_credit.order_split':
        'Credits reverted from split order',
      'store_credits.fields.revert_credit.order_edit':
        'คืนเครดิตจากคำสั่งซื้อที่ถูกแก้ไข',
      'store_credits.fields.revert_credit.return_order_revert':
        'เครดิตที่ให้คืน; จากการคืนคำสั่งซื้อ',
      'store_credits.fields.product_review_reward':
        'ตรวจสอบคำสั่งซื้อ <a href="{{link}}">{{ orderNumber }}</a> แล้ว',
      'store_credits.no_data': 'ไม่มีบันทึกเครดิต',

      'member_points.fields.product_review_reward':
        'ตรวจสอบคำสั่งซื้อ <a href="{{link}}">{{ orderNumber }}</a> แล้ว',
      'member_points.fields.revert_member_point.order_edit':
        'คืนคะแนนจากคำสั่งซื้อที่ถูกแก้ไข',
      'member_points.fields.revert_member_point.return_order_revert':
        'คะแนนที่ให้คืน; จากการคืนคำสั่งซื้อ',
      'member_points.fields.member_info_reward':
        'คะแนนสมาชิกเมื่อตั้งค่าข้อมูลสมาชิกเสร็จสมบูรณ์',

      'error.404.title': 'ขออภัย',
      'error.404.description': 'ไม่มีหน้านี้',
      'error.401.title': 'ขออภัย',
      'error.401.description': 'คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้',
      'error.403.title': 'ขออภัย',
      'error.403.description': 'คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้',
      'error.403.not_signed_in.message': 'อาจเป็นเพราะคุณไม่มีสิทธิ์',
      'error.403.not_signed_in.anchor': 'เข้าสู่ระบบ',
      'error.continue': 'ช้อปปิ้งต่อตอนนี้!',

      'orders.comments.title': 'ความคิดเห็นของร้านค้าและลูกค้า',
      'orders.comments.text.name': 'ความคิดเห็น',
      'orders.comments.text.placeholder': 'เขียนความคิดเห็นสำหรับออเดอร์นี้',

      'orders.fields.payment_fee': 'ค่าธรรมเนียมการชำระเงิน',

      'orders.invoices.title': 'ใบแจ้งหนี้',
      'orders.invoices.carrier': 'ผู้ขนส่งทั่วไป',
      'orders.invoices.carrier_type.member': 'สมาชิก',
      'orders.invoices.carrier_type.mobile_barcode': 'บาร์โค้ดมือถือ',
      'orders.invoices.carrier_type.npc_barcode': 'บาร์โค้ด NPC',
      'orders.invoices.get_invoice': 'ฉันต้องการในเสร็จ',

      'orders.notifications.title': 'การแจ้งเตือนอัปเดตออเดอร์',

      'order.payments.login.confirm':
        'Please log in and go to pay again or update credit card information!',

      'model.credit_card.title': 'ข้อมูลบัตรเครดิตของคุณ',
      'model.credit_card.subtitle':
        'โปรดป้อนข้อมูลบัตรเครดิตของคุณด้านล่างเพื่ออัปเดต',
      'model.credit_card.choose_card': 'Fast checkout with your saved card',
      'model.credit_card.use_new_card': 'Use a new card',
      'model.slpayment.security_agreement':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',
      'model.slpayment.recurring_pay_agreement':
        'We’ll use the payment details you just provided for subsequent recurrent payments. By submitting, you agree to the <a href="https://shopline.tw/about/product_subscription_agreements_en" target="_blank" rel="noopener">Term Purchase bank card Withholding Agreement.</a>',
      'model.update_credit_card_for_product_subscription.success':
        'Credit card information updated successfully',
      'model.update_credit_card_for_product_subscription.fail':
        'The payment information for Product Subscription is invalid. Please try again.',

      'sms_messenger_checkbox.subscribe': 'ส่งโดยข้อความ',

      'facebook_messenger_checkbox.subscribed':
        'ส่งไปที่ {{topic}} ผ่าน <img src="https://{{image_host}}/assets/misc/fb_messenger_36x.png" class="fb-app-icon">Messenger',
      'facebook_messenger_checkbox.turn_off': 'ปิด',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        'การแจ้งเตือนอัปเดตออเดอร์',
      'facebook_messenger_checkbox.topics.MARKETING':
        'อัปเดตร้านค้าและโปรโมชั่น',

      'media.validation.type': 'รองรับเฉพาะไฟล์รูปแบบ jpeg และ png เท่านั้น',
      'media.validation.error.file_size': 'โปรดอัปโหลดภาพของน้อยกว่า 5MB.',
      'media.validation.error.file_attachment_size':
        'โปรดอัปโหลดไฟล์น้อยกว่า 2MB ของ.',
      'media.validation.error': 'การอัปโหลดภาพเกิดข้อผิดพลาด',

      'attachment.upload.description':
        'ไฟล์ต้องมีขนาดไม่เกิน 2MB \nประเภทไฟล์ที่อนุญาต: pdf, doc, docx, xls, xlsx, csv, jpg, jpeg, png, gif',

      'imagepicker.title': 'อัปโหลดภาพ',

      'promotions.section.discount_summary.set.on_item':
        'รับส่วนลด <b>{{ discount_value }}</b> บน<b>สินค้าที่ร่วมรายการ</b>',
      'promotions.section.discount_summary.set.on_order':
        'รับส่วนลด <b>{{ discount_value }}</b> สำหรับ<b>ออเดอร์ทั้งหมด</b>',
      'promotions.section.discount_summary.discount_value':
        '<b>{{ type == "amount" ? สกุลเงิน : ""  }}{{ value }}{{ type == "percentage" ? "%":""  }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>สินค้าที่ร่วมรายการ</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>ออเดอร์ทั้งหมด</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        ' ด้วยการซื้อ<b>สินค้าที่ร่วมรายการ</b>มูลค่ามากกว่า<b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        ' ที่มีการซื้อมากกว่า <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }} รายการ',
      // ซื้อ {?} ในกลุ่ม A และ {?} ในกลุ่ม B ในราคา NT${?}
      'promotions.section.discount_summary.criteria.bundle_group':
        'สินค้าที่เลือก: ซื้อ {{ red_count }} <span class="bundle-group-label bundle-group-red">ในกลุ่ม A</span> และ {{ green_count }} <span class="bundle-group-label bundle-group-green">ในกลุ่ม B</span> ในราคา {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_amount':
        'สินค้าที่เลือก: ซื้อ {{ red_count }} <span class="bundle-group-label bundle-group-red">ในกลุ่ม A</span> และ {{ green_count }} <span class="bundle-group-label bundle-group-green">ในกลุ่ม B</span> ลด {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_percentage':
        'สินค้าที่เลือก: ซื้อส {{ red_count }} <span class="bundle-group-label bundle-group-red">ในกลุ่ม A</span> และ {{ green_count }} <span class="bundle-group-label bundle-group-green">ในกลุ่ม B</span> ลด {{ total }}%',
      'promotions.section.discount_summary.criteria.bundle_group_gift':
        'สินค้าที่เลือก: ซื้อ {{ red_count }} <span class="bundle-group-label bundle-group-red">ในกลุ่ม A</span> และ {{ green_count }} <span class="bundle-group-label bundle-group-green">ในกลุ่ม B</span> รับของขวัญฟรี {{ total }} ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_pricing':
        'สินค้าที่เลือก: ซื้อสินค้า {{ itemCount }} ชิ้นลด {{ discount_value }}',
      'promotions.section.discount_summary.criteria.bundle_percentage':
        'สินค้าที่เลือก: ซื้อสินค้า {{ itemCount }} ชิ้นลด {{ discount_value }}%',
      'promotions.section.discount_summary.criteria.bundle_amount':
        'สินค้าที่เลือก: ซื้อสินค้า {{ itemCount }} ชิ้นลด {{ discount_value }}',
      'promotions.section.discount_summary.criteria.bundle_gift':
        'สินค้าที่เลือก: ซื้อสินค้า {{ itemCount }} รับของขวัญฟรี {{ discount_value }} {{ giftName }}',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' ซื้อเพิ่มขึ้นเพื่อรับส่วนลดที่มากขึ้น',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption': 'ลด {{ value }}%',

      'promotions.page.term.title': 'เงื่อนไข',
      'promotions.page.display_local_timezone':
        'แสดงเวลาในพื้นที่ของคุณ <br/>(GMT{{offset}})',
      'promotions.page.start_at':
        'เริ่มต้น <span class="local-datetime">{{date}}</span>',
      'promotions.page.end_at':
        'สิ้นสุด <b class="local-datetime">{{date}}</b>',

      'promotions.exclude.items.hint.title':
        'สินค้าราคาที่ดีที่สุดไม่สามารถใช้ได้กับ:',
      'promotions.exclude.items.hint.order_discount': 'ส่วนลดการสั่งซื้อ',
      'promotions.exclude.items.hint.member_tier': 'ข้อเสนอสำหรับสมาชิก',
      'promotions.exclude.items.hint.user_credit': 'การใช้เครดิต',
      'promotions.exclude.items.hint.member_point': 'คะแนนส่วนลด',

      'product.page.title.product-info': 'สินค้า',
      'product.page.quantity': 'ปริมาณ',
      'product.page.unit_price': 'ราคาต่อหน่วย',
      'product.page.subtotal': 'ยอดรวมส่วนหนึ่ง',
      'product.page.add_to_cart_count':
        'เพิ่ม <span class="order-detail-count">{{count}}</span> รายการแล้ว',
      'product.tier_member_only': 'สำหรับ {{tierName}} เท่านั้น',

      'products.quick_cart.quantity': 'ปริมาณ',
      'products.quick_cart.preorder_now': 'พรีออเดอร์ตอนนี้',
      'products.quick_cart.add_to_cart': 'เพิ่มไปที่ตะกร้าสินค้า',
      'products.quick_cart.quantity_invalid': '',
      'products.quick_cart.out_of_stock': 'หมด',
      'products.quick_cart.out_of_stock_hint':
        'ของไม่เพียงพอ\n ไม่ได้เพิ่มรายการของคุณในตะกร้าสินค้า',
      'products.quick_cart.out_of_number_hint': 'หมด/จำนวนพรีออเดอร์',
      'products.quick_cart.low_stock_hint':
        'ของไม่เพียงพอ\n โปรดปรับปริมาณของคุณ',
      'products.quick_cart.reached_max_purchase_quantity':
        'จำกัด {{ message }} ต่อออเดอร์',
      'products.quick_cart.quantity_of_stock_hint':
        'มีเพียง {{message}} รายการที่เหลืออยู่',
      'products.quick_cart.messagetobuy':
        'โปรดส่งข้อความถึงเจ้าของร้านเพื่อขอละเอียดการสั่งซื้อ',
      'products.quick_cart.message': 'ข้อความ',
      'products.quick_cart.show_more': 'รายละเอียดเพิ่มเติม',
      'products.message.title': 'คุณแน่ใจหรือว่าจะลบรายการนี้?',
      'products.message.button_cancel': 'ยกเลิก',
      'products.message.button_ok': 'ตกลง',
      'products.purchase_limit_hint': 'จำกัด {{limit}} ชิ้นต่อ 1 ท่าน',
      'purchase_limit_hint.purchasable':
        'จำกัด {{limit}} ชิ้นต่อ 1 ท่าน คุณสามารถซื้อเพิ่มได้อีก {{purchasable_qty}} ชิ้นเท่านั้น',
      'products.purchase_limit_hint_can_not_purchase':
        'จำกัด {{limit}} ชิ้นต่อ 1 ท่าน คุณได้ซื้อครบจำนวนชิ้นที่กำหนดแล้ว',

      'products.category.advance_filter.title': 'ตัวกรอง',
      'products.category.advance_filter.clear': 'ตัวเลือกที่ชัดเจน',
      'products.category.advance_filter.clear_all': 'ลบทั้งหมด',
      'products.category.advance_filter.apply': 'ใช้ตัวกรอง',
      'products.category.advance_filter.apply.hint':
        'โปรดเลือกตัวเลือกตัวกรองก่อน',
      'products.category.advance_filter.show_more': 'แสดงมากขึ้น',
      'products.category.advance_filter.show_less': 'แสดงน้อยลง',
      'products.category.advance_filter.type.color': 'สี',
      'products.category.advance_filter.type.size': 'ขนาด',
      'products.category.advance_filter.type.brand': 'แบรนด์',
      'products.category.advance_filter.type.material': 'วัสดุ',
      'products.category.advance_filter.type.price': 'ราคา ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price':
        'จำนวนเงินขั้นต่ำ',
      'products.category.advance_filter.placeholder.max_price':
        'จำนวนเงินสูงสุด',

      'products.variant_selector.hide': 'ซ่อน',
      'products.variant_selector.view_other_variations':
        'ดูตัวแปร {{count}} อื่น ๆ',

      'hk_sfplus.region': 'ภูมิภาค',
      'hk_sfplus.area': 'พื้นที่',
      'hk_sfplus.district': 'เขต',
      'hk_sfplus.address': '{{region}}, {{district}}, {{area}}',

      'tcat.time_slot.title': 'ช่วงเวลาที่มาถึง',
      'tcat.time_slot.01': 'ก่อน 13:00',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': 'ช่วงเวลาใดก็ได้',

      'subscribe.success': 'สมัครสำเร็จ',
      'subscribe.failure': 'การสมัครล้มเหลว กรุณาติดต่อร้านค้า',
      'subscribe.line.failure':
        'การสมัครล้มเหลว กรุณาเข้าสู่ระบบ LINE อีกครั้ง',
      'subscribe.line.failure.already_used':
        'สมัครล้มเหลว บัญชี LINE นี้ถูกใช้สมัครแล้วโดยสมาชิกท่านอื่น กรุณาลงชื่อเข้าสู่ระบบด้วยบัญชี LINE และลองอีกครั้ง',
      'subscribe.line.failure.different_id':
        'Failed to subscribe. Please log in with the LINE account of the member account.',
      'subscribe.facebook.failure':
        'การสมัครล้มเหลว กรุณาเข้าสู่ระบบ Facebook อีกครั้ง',
      'subscribe.failure.already_bound':
        'สมัครล้มเหลว บัญชีโซเชียลนี้ถูกผูกกับสมาชิกท่านอื่นแล้ว กรุณาติดต่อร้านค้าเพื่อยกเลิกการผูก',
      'unsubscribe.success': 'ถอนการสมัครสำเร็จ',
      'unsubscribe.failure':
        'ไม่สามารถยกเลิกการสมัครได้ โปรดลองอีกครั้งในภายหลัง',

      'order_comment_and_message.general_error':
        'ระบบขัดข้อง กรุณาติดต่อเจ้าของร้านค้าหรือลองใหม่อีกครั้งภายหลัง',
      'order_comment_and_message.rate_limit_error':
        'ข้อความถึงจำนวนที่จำกัดไว้แล้ว โปรดลองอีกครั้งในวันพรุ่งนี',

      'member_referral.discount': 'แคมเปญแนะนำสมาชิก',
      'member_referral.rules': 'กฎเกณฑ์ของแคมเปญ',
      'member_referral.code.copy_and_share':
        'แชร์ลิงค์ให้กับเพื่อนของคุณ เพื่อรับเครดิตร้านค้า.',
      'member_referral.code.remarks': 'กฎเกณฑ์ของแคมเปญ',
      'member_referral.generate.code': 'สร้างลิงค์ผู้อ้างอิง',
      'member_referral.generate.code.success': 'สร้างลิงค์อ้างอิงแล้ว',
      'member_referral.copy.link': 'คัดลอกลิงค์',
      'member_referral.copy.link.success': 'ลิงค์คัดลอกเรียบร้อยแล้ว',
      'member_referral.welcome_credit.popup.title':
        'ยินดีด้วยค่ะ คุณได้รับ {{reward_value}} เครดิต',
      'member_referral.welcome_credit.popup.content':
        'คุณได้รับ {{reward_value}} เครดิตคุณสามารถแนะนำเพื่อนเพื่อรับเครดิตที่มากขึ้น',
      'member_referral.welcome_credit.popup.continue': 'ซื้อเลย',

      'member_points.redeem_cash_and_earn_from_order_description':
        'ทุก {{costMoney}} รับ {{earnPoint}} คะแนน ทุก {{costPoint}} คะแนน ลด {{discountMoney}}',
      'member_points.redeem_cash_description':
        'ทุก {{costPoint}} คะแนน ลด {{discountMoney}}',
      'member_points.earn_from_order_description':
        'ทุก {{costMoney}} รับ {{earnPoint}} คะแนน',
      'member_points.equal_cash': 'เท่ากับ {{money}}',
      'member_points.title': 'คะแนนสมาชิก',
      'member_points.fields.expired': 'คะแนนหมดอายุแล้ว',

      'lock_inventory.tooltip':
        'จองได้ถึง {{expired_time}} น.\n(ไลฟ์สตรีมเมื่อ {{start_time}} น.:{{event_name}})\nแสดงตามเขตเวลาท้องถิ่นของคุณ（GMT {{timezone}}）',
      'facebook_banner.description':
        'คุณกำลังเข้าสู่เว็บไซต์ผ่านทางเฟสบุ๊ก อิน-แอป เบราว์เซอร์ หากคุณพบปัญหาในการอัปโหลดรูปภาพ กรุณาเลือกเบราว์เซอร์ใหม่ <span>คัดลอกลิ้งก์</span>',
      'in_app_browser_popup.desc':
        'หากต้องการเข้าสู่ระบบและชำระเงินเพื่อประสบการณ์การช็อปปิ้งที่ดียิ่งขึ้น เราขอแนะนำให้คุณเปิดเบราว์เซอร์เริ่มต้น',
      'fb_in_app_browser_popup.desc':
        'You’re visiting the website via built-in browser of Facebook app, please use another browser in case of any failure during checkout. ',
      'fb_in_app_browser_popup.copy_link': 'Copy Link.',

      'product_set.build': 'สร้างเซ็ตสินค้าของคุณ',
      'product_set.subtitle.unqualified':
        'เพิ่มสินค้าเหล่านี้ตอนนี้; เลือก <span class="highlight-label">{{ quantity }}</span> สินค้าเพิ่มเพื่อจัดเซตให้สมบูรณ์และเพิ่มลงในตะกร้าสินค้า',
      'product_set.subtitle.qualified':
        'คุณสามารถใช้ส่วนลดได้ เพิ่มเซตลงในรถเข็น!',
      'product_set.special_offer': 'เซ็ตสินค้าราคาพิเศษ',
      'product_set.items_needed':
        ' ต้องการ <span class="highlight-label">{{ quantity }}</span> รายการ',
      'product_set.add_to_set': 'เพิ่มเข้ามาในเซ็ต',
      'product_set.items_needed.diff':
        '{{ currentQuantity }} รายการถูกเลือก คุณยังต้องการอีก <span class="highlight-label">{{ stillNeedQuantity }}</span> รายการเพื่อที่จะได้รับส่วนลด',
      'product_set.add_to_cart': 'หยิบใส่รถเข็น',
      'product_set.items_selected':
        '<span class="highlight-label">{{ quantity }}</span> รายการถูกเลือก',
      'product_set.items_needed.hint':
        'เพิ่ม <span class="highlight-label">{{ quantity }}</span> สินค้าเพื่อจัดเซตให้สมบูรณ์',
      'product_set.quantity_label.unqualified': 'ต้องการ {{ quantity }} รายการ',
      'product_set.quantity_label.qualified': '{{ quantity }} รายการถูกเลือก',
      'product_set.reach_limit': 'ถึงขีดจำกัดการซื้อ',
      'product_set.reach_limit.congrats':
        '<span class="highlight-label">{{ quantity }}</span> รายการถูกเลือก ยินดีด้วยสำหรับส่วนลดที่คุณได้รับ!',
      'product_set.no_items_selected': 'ไม่มีรายการที่ถูกเลือก',
      'product_set.quick_cart.add_to_set': 'เพิ่มในเซ็ต',
      'product_set.exceed_limit':
        'เกินขีดจำกัดการซื้อเซ็ทสินค้า โปรดปรับปริมาณของคุณ',
      'product_set.set_includes': 'เซ็ตประกอบด้วย',
      'product_set.quantity': 'จำนวนเซ็ตสินค้า',
      'product_set.sold_out': 'สต็อกไม่เพียงพอ',
      'product_set.please_select': 'โปรดเลือกสินค้าของคุณ',
      'product_set.not_enough_stock.please_remove':
        'สต็อกไม่เพียงพอ กรุณาลบรายการนี้',
      'product_set.not_enough_stock': 'สต็อกไม่เพียงพอ โปรดแก้ไขจำนวน',

      'store_stock.loading': 'กำลังตรวจสอบสต็อกสินค้า',
      'store_stock.take_a_while': 'จะใช้เวลาสักครู่...',
      'store_stock.current_availability': 'ความพร้อมของร้านค้าในปัจจุบัน',
      'store_stock.description':
        'We sell products based on on-site availability and only reserve inventory for customers who have completed their booking. We recommend placing your order and confirming your pickup date before coming to the store.',
      'store_stock.available': 'Available for online purchase, pickup at store',
      'store_stock.sold_out': 'ขายหมดแล้ว',

      'multi_checkout.items.title': 'ตะกร้าสินค้า',
      'multi_checkout.promotions.title': 'ส่วนลด',
      'multi_checkout.change.payment_type.modal.title':
        'You are About to Change Payment Type',
      'multi_checkout.change.payment_type.modal.description':
        'You will need to reselect delivery method and fill out shipping information again once you change the payment type.',
      'multi_checkout.change.payment_type.modal.confirm': 'Update',
      'multi_checkout.change.payment_type.modal.cancel': 'Cancel',
      'multi_checkout.destination_section.title': 'Destination Information',
      'multi_checkout.destination_section.address_type.new': 'ที่อยู่ใหม่',
      'multi_checkout.destination_section.delivery_type.label':
        'Selected Delivery Method:',
      'multi_checkout.destination_section.pick_store.label': 'ค้นหาร้านค้า',
      'multi_checkout.destination_section.pick_store.store_code':
        'Selected Store Code',
      'multi_checkout.destination_section.pick_store.store_name':
        'Selected Store Name',
      'multi_checkout.destination_section.pick_store.store_address': 'Address',
      'multi_checkout.destination_section.dynamic_fee.hint':
        'The freight will be displayed after filling in address',
      'multi_checkout.delivery_section.title':
        'Select Payment Type and Delivery Method',
      'multi_checkout.delivery_section.country.label': 'ประเทศ / ภูมิภาค',
      'multi_checkout.delivery_section.payment_type.label': 'Payment Type',
      'multi_checkout.delivery_section.delivery_option.label':
        'ตัวเลือกการจัดส่ง',
      'multi_checkout.delivery_section.collapse.hint': 'Expand',
      'multi_checkout.delivery_section.ncod': 'Non Cash on Delivery',
      'multi_checkout.delivery_section.cod': 'Cash on Delivery',
      'multi_checkout.delivery_section.empty_delivery_methods':
        'ไม่มีตัวเลือกที่เกี่ยวข้อง',
      'multi_checkout.summary_section.title': 'รายละเอียดคำสั่งซื้อ',
      'multi_checkout.next_step': 'Next',
      'multi_checkout.summary_section.delivery_type.label':
        'วิธีการจัดส่งสินค้า',
      'multi_checkout.summary_section.check_payment_instructions':
        'Check Payment Instructions',
      'multi_checkout.summary_section.expand_payment_instructions': 'Expand',
      'multi_checkout.summary_section.confirm': 'Confirm',
      'multi_checkout.cart_tag_unnamed': '(unnamed)',
      'multi_checkout.checkout.destination.title': 'Destination Information',
      'multi_checkout.checkout.delivery_time.description':
        'รายละเอียดเวลาจัดส่งสินค้า:',

      'user_reminder.member_referral.user_credit':
        'สมัครสมาชิกเพื่อรับ {{ value }} คะแนน',
      'user_reminder.member_referral.member_point':
        'สมัครสมาชิกเพื่อรับ {{ value }} คะแนน',
      'user_reminder.member_referral.action': 'สมัครสมาชิกตอนนี้',
      'verification.email.trial_merchant.reach_limit.failure':
        'ไม่สามารถส่งอีเมลยืนยันได้ระหว่างการทดลองใช้งานของร้านค้า หากต้องการใช้งาน โปรดติดต่อร้านค้าเพื่อขอความช่วยเหลือ ขอบคุณ',
    });
    $translateProvider.preferredLanguage('th');
  },
]);
